import { defineStore } from 'pinia'
import { useWebsocketStore } from './websocketStore'
import { OnNotification2Document } from '@/graphql/generated'

type Message = {
  id: number
  message: string
  imagesetId?: string
}

export const useMessageStore = defineStore('message', () => {
  const messages = ref<Message[]>([])
  const websocketStore = useWebsocketStore()

  const fetchMessages = async () => {
    // Fetch messages from the server and update the messages ref
    messages.value = [
      { id: 1, message: 'Hey, your image XYZ just finished processing' },
      { id: 2, message: 'You have a new follower' },
    ]
  }

  const addMessage = (message: string, imagesetId?: string) => {
    const newId = messages.value.length > 0 ? Math.max(...messages.value.map((m) => m.id)) + 1 : 1
    messages.value.unshift({ id: newId, message, imagesetId })
  }

  const deleteMessage = (id: number) => {
    messages.value = messages.value.filter((n) => n.id !== id)
    // Make a request to the server to delete the message
  }

  const clearMessages = () => {
    messages.value = []
    // Make a request to the server to clear all messages
  }

  const subscribeToNotifications = (stream: string | null | undefined) => {
    if (stream) {
      websocketStore.subscribe(OnNotification2Document.loc!.source.body, { stream })
    }
  }

  return {
    messages,
    fetchMessages,
    addMessage,
    deleteMessage,
    clearMessages,
    subscribeToNotifications,
  }
})
