import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

// when new locale will be added
// it should be pushed to defaultLocaleList
export const defaultLocaleList = ['en', 'nl']
export const localeList = [...defaultLocaleList, 'de', 'it', 'ru', 'zh']

// default locale detector
let userLocale = localStorage.getItem('Starpx.lang') || window.navigator.language.split('-')[0]
if (!localeList.includes(userLocale)) {
  userLocale = 'en'
}

export const i18n = createI18n({
  legacy: false,
  locale: userLocale || 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})

export const setGlobalLocale = async (locale: string) => {
  // Load locale if not available yet
  if (!i18n.global.availableLocales.includes(locale)) {
    const messages = await fetch(`/static/locales/${locale}.json`)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw new Error('Something went wrong!')
      })
      .catch(null)

    if (messages) {
      i18n.global.setLocaleMessage(locale, messages)
    }
  }

  i18n.global.locale.value = locale
  localStorage.setItem('Starpx.lang', locale)
}

// preload if not included in defaults
if (!defaultLocaleList.includes(userLocale)) {
  setGlobalLocale(userLocale)
}

// NOTE: hide I18n select and alway set lang = en
setGlobalLocale('en')
