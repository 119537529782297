<template>
  <div class="relative mx-auto w-full max-w-4xl">
    <div class="flex items-center justify-between">
      <span v-if="processingAndQueueFileList.length">Uploading...</span>
      <div class="ml-auto space-x-2">
        <UiButton
          v-if="failedUploadsList.length"
          type="button"
          size="small"
          theme="danger"
          @click="clearFailedUploads"
        >
          Clear failed uploads
        </UiButton>
        <UiButton
          v-if="processingAndQueueFileList.length"
          type="button"
          size="small"
          theme="danger"
          @click="cancelInProgressUploads"
        >
          Cancel
        </UiButton>
      </div>
    </div>

    <div v-if="uploadList.length" class="pb-16">
      <UploadFileDisplay
        class="mt-4"
        v-for="upload in uploadList"
        :upload="upload"
        :key="upload.id"
      />
    </div>

    <div v-else class="mx-auto mt-10 flex max-w-md flex-col items-center">
      <div class="w-full rounded-lg bg-blue-100 px-4 py-2 dark:bg-blue-600">
        <p class="text-center text-base">{{ $t('uploader.list.empty') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { checkBooleanValues } from '@/core/utils/CheckBooleanValues'
import UiButton from '@c/Ui/Button.vue'
import UploadFileDisplay from '@c/Uploader/UploadFileDisplay.vue'

const uploadStore = useUploadStore()
const { uploadStates, uploadSessions, uploadFilter } = storeToRefs(uploadStore)

const route = useRoute()
const { getAllUploadsFromDB, removeUploadFromDB } = await useFileDB()
const files = ref(await getAllUploadsFromDB())
watch(uploadStates, async () => {
  files.value = await getAllUploadsFromDB()
})

const routeSid = computed(() => {
  return route.params.sid as string
})

// corresponding uploads by session ids
const matchingStates = computed(() => {
  return uploadStates.value.filter((x) => (routeSid.value ? x.sid === routeSid.value : true)) || []
})

// Display uploads by status filter
const uploadList = computed(() => {
  let tStates = matchingStates.value
  if (!routeSid.value) {
    tStates = tStates.filter((set) => {
      if (checkBooleanValues(uploadFilter.value)) return true

      let match = false
      const isCompleted = Boolean(set.hash)
      const isErrored = Boolean(set.error)

      if (uploadFilter.value.completed) {
        match = match || isCompleted
      }
      if (uploadFilter.value.failed) {
        match = match || isErrored
      }
      if (uploadFilter.value.uploading) {
        // Uploading or Queued
        match = match || set.uploading || (!isCompleted && !isErrored)
        // match = match || isUploading
      }

      return match
    })
  }

  const uploadIdsToDisplay = tStates.map((x) => x.fuid)
  const filterToDisplay = files.value.filter((x) => !x.error && uploadIdsToDisplay.includes(x.id))
  return sortByField(filterToDisplay, 'createdAt', 'desc')
})

// Failed uploads list
const failedUploadsList = computed(() => {
  let tStates = matchingStates.value

  tStates = tStates.filter((s) => {
    const isErrored = Boolean(s.error)

    return isErrored
  })

  const uploadIdsToDisplay = tStates.map((x) => x.fuid)

  return files.value.filter((x) => !x.error && uploadIdsToDisplay.includes(x.id))
})

// In-progress uploads list
const processingAndQueueFileList = computed(() => {
  let tStates = matchingStates.value

  tStates = tStates.filter((s) => {
    const isProcessingOrQueue = !s.hash && !s.error
    return isProcessingOrQueue
  })

  return tStates.filter((x) => files.value.map((f) => f.id).includes(x.fuid))
})

const clearFailedUploads = async () => {
  failedUploadsList.value.forEach((u) => {
    uploadStore.removeUpload(u.id)
  })
}

const { busEmit } = useEventBus()
const cancelInProgressUploads = async () => {
  processingAndQueueFileList.value.forEach((f) => {
    busEmit('pauseUpload', { id: f.fuid })
    removeUploadFromDB(f.fuid)
    uploadStore.removeUpload(f.fuid)
  })
}
</script>
