import { UserProfile } from '@/graphql/generated'
import type { Client } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'
import { Logging } from './type'

export class SentryLogging implements Logging {
  client: Client | undefined
  init(app: App<Element>, router: Router): void {
    const dsn = import.meta.env.VITE_SENTRY_DSN
    if (!dsn) {
      throw new Error('SENTRY_DSN can not empty')
    }

    this.client = Sentry.init({
      app,
      dsn,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [
        // 'localhost',
        /^https:\/\/dev\.starpx\.com/,
        /^https:\/\/starpx\.com/,
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      // https://docs.sentry.io/platforms/javascript/guides/vue/best-practices/offline-caching/
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

      // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
      trackComponents: true,

      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development',

      // NOTE: only log if user is exist
      beforeSend(event) {
        if (event.user?.client_logging_enabled) {
          return event
        }
        return null
      },
    })
  }
  setUser(user: UserProfile | undefined): void {
    if (!this.client) {
      throw new Error('Have to init Sentry Client first.')
    }

    if (!user) {
      Sentry.setUser(null)
    } else {
      Sentry.setUser({
        username: user.email,
        client_logging_enabled: user.client_logging_enabled,
      })
    }
  }
}

// NOTE: singleton pattern
export const sentryLogging = new SentryLogging()
