<template>
  <span :data-id="upload.id"></span>
</template>

<script setup lang="ts">
import type { IUploadState, IUploadWithFile } from '@/core/interface/Upload'
import type { IB2UploadDto, IB2UploadPartDto } from '@/core/interface/Backblaze'
import { logErrorToSentry } from '@/core/utils/SentryErrorLogger'
import * as Sentry from '@sentry/vue'

const props = defineProps<{ upload: IUploadWithFile }>()
const emit = defineEmits(['onRemove'])

const STORAGE_DRIVER = import.meta.env.VITE_STORAGE_DRIVER || 'backblaze'
const DROPBOX_LARGE_FILE_THRESHOLD = 150 * 1024 * 1024 // 150MB in bytes
const CHUNK_SIZE = 8 * 1024 * 1024 // 8MB chunks
const ALWAYS_USE_UPLOAD_SESSION = import.meta.env.VITE_DROPBOX_ALWAYS_USE_UPLOADSESSION === 'true'
const MAX_RETRIES = import.meta.env.VITE_MAX_RETRIES_TIME
const DEFAULT_RETRY_DELAY = 5 // seconds
const DROPBOX_BATCH_UPLOAD_ENABLED = import.meta.env.VITE_DROPBOX_BATCHUPLOAD === 'y'

const log = useLogger()
const uploadStore = useUploadStore()
const {
  uploadEndpoints,
  uploadPartialEndpoints,
  uploadPaths,
  uploadStates,
  uploadProgress,
  uploadQue,
  uploadCompleted,
} = storeToRefs(uploadStore)

const { bus } = useEventBus()

let request = ref<XMLHttpRequest>(new XMLHttpRequest())
let chunkRequests = ref<XMLHttpRequest[]>([])
// null - not started, false - compleate, true - running
let chunkQue = ref<Array<null | boolean>>([])
let chunkSums = ref<string[]>([])

// corresponding state of the file
const uploadState = computed(() => {
  return uploadStates.value.find((x) => x.fuid === props.upload.id)
})

const uploadProgressBytes = computed(() => {
  return uploadProgress.value.find((x) => x.fuid === props.upload.id)?.bytesUploaded
})

const sid = computed(() => {
  return uploadState.value?.sid
})

// match endpoints by session id
const statesBySid = computed(() => {
  return {
    que: uploadQue.value.find((x) => x.sid === sid.value)?.list || [],
    endpoints: uploadEndpoints.value.find((x) => x.sid === sid.value)?.list || [],
    partialEndpoints: uploadPartialEndpoints.value.find((x) => x.sid === sid.value)?.list || [],
  }
})

const inQue = computed(() => {
  return statesBySid.value.que.find((x) => x.id === props.upload.id)
})

// uploads by chunks
const partialEndpoints = computed(() => {
  if (!inQue.value) return null

  return statesBySid.value.partialEndpoints.find((x) => x.fuid === props.upload.id)
    ?.b2_upload_endpoints
})

// regular upload
const uploadEndpoint = computed(() => {
  if (!inQue.value) return null

  return statesBySid.value.endpoints[inQue.value.endpoint]
})

// blackbaze file name (only regular)
const uploadPath = computed(() => {
  return uploadPaths.value.find((x) => x.fuid === props.upload.id)
})

const updateStoreUpload = (payload: Partial<IUploadState>) => {
  uploadStore.updateUploadState({
    id: props.upload.id,
    payload,
  })
}

const handleUploadBackblaze = () => {
  if (
    !uploadPath.value ||
    uploadState.value?.hash ||
    !uploadEndpoint.value ||
    uploadState.value?.paused
  )
    return

  updateStoreUpload({
    uploading: true,
    error: false,
    bytesTotal: props.upload.file.size,
  })

  let req = request.value
  req.open('POST', uploadEndpoint.value.b2_uploadurl)

  req.setRequestHeader('Authorization', uploadEndpoint.value.b2_authtoken)
  req.setRequestHeader('X-Bz-File-Name', uploadPath.value.b2_path)
  req.setRequestHeader('Content-Type', 'b2/x-auto')
  req.setRequestHeader('X-Bz-Content-Sha1', 'do_not_verify')

  const bytesLoaded = (uploadProgressBytes.value || 0) as number
  req.setRequestHeader('Range', `bytes=${bytesLoaded}-${props.upload.file.size}`)

  let uploadData = props.upload.file.slice(bytesLoaded, uploadState.value?.bytesTotal)

  req.upload.onprogress = (e) => {
    uploadStore.updateUploadProgress({
      id: props.upload.id,
      payload: {
        bytesUploaded: bytesLoaded + e.loaded,
      },
    })
  }

  req.onload = function () {
    if (!req) return

    if (req.status === 200) {
      const responce = JSON.parse(req.response) as IB2UploadDto

      updateStoreUpload({
        hash: responce.fileId,
      })

      uploadCompleted.value.push({
        sid: uploadState.value?.sid || 'undefined',
        fuid: props.upload.id,
        shasum: [responce.contentSha1],
      })
    } else {
      handleUploadError(req.status)
    }

    updateStoreUpload({
      uploading: false,
    })

    // add next file to upload que or remove
    queNextFile()
  }

  req.onerror = () => {
    updateStoreUpload({
      uploading: false,
      error: true,
      errorReason: 'network',
    })
  }

  req.send(uploadData)

  request.value = req
}

const handleUploadDropbox = async () => {
  if (
    !uploadPath.value ||
    uploadState.value?.hash ||
    !uploadEndpoint.value ||
    uploadState.value?.paused
  )
    return

  updateStoreUpload({
    uploading: true,
    error: false,
    bytesTotal: props.upload.file.size,
  })

  const file = props.upload.file
  const filePath = uploadPath.value.b2_path

  if (DROPBOX_BATCH_UPLOAD_ENABLED) {
    await uploadByBatch()
  } else {
    // Use the existing single-file upload method
    if (ALWAYS_USE_UPLOAD_SESSION || file.size > DROPBOX_LARGE_FILE_THRESHOLD) {
      // For all files when ALWAYS_USE_UPLOAD_SESSION is true, or for files > 150MB, use upload session
      await uploadLargeFile(file, filePath)
    } else {
      // For files <= 150MB, use single upload
      await uploadSmallFile(file, filePath)
    }
  }
}

const uploadByBatch = async (): Promise<void> => {
  try {
    const startBatchResponse = await retryableRequest<Response>(startBatch)
    const startBatchResult = await startBatchResponse.json()
    const sessionIds: string[] = startBatchResult.session_ids

    await retryableRequest(() => uploadChunksBatch(sessionIds))

    const finishBatchResponse = await retryableRequest<Response>(() => finishBatch(sessionIds))
    const finishBatchResult = await finishBatchResponse.json()
    handleUploadResponse({
      status: finishBatchResponse.status,
      response: JSON.stringify(finishBatchResult?.entries?.[0]),
    })

    updateStoreUpload({ uploading: false })
    queNextFile()
  } catch (error) {
    console.trace({ error })

    updateStoreUpload({ error: true, errorReason: 'upload_failed' })
  }
}

const startBatch = async (): Promise<Response> => {
  return fetch('https://api.dropboxapi.com/2/files/upload_session/start_batch', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${uploadEndpoint.value!.b2_authtoken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      num_sessions: 1,
      session_type: 'concurrent',
    }),
  })
}

const uploadChunksBatch = async (sessionIds: string[]): Promise<Response> => {
  const totalChunks = Math.ceil(props.upload.file.size / CHUNK_SIZE)

  const entries = Array(totalChunks)
    .fill(null)
    .map((_, i) => {
      const offset = i * CHUNK_SIZE
      const length = Math.min(CHUNK_SIZE, props.upload.file.size - offset)

      return {
        cursor: {
          session_id: sessionIds[0],
          offset: Math.min(offset, props.upload.file.size),
        },
        close: offset + CHUNK_SIZE >= props.upload.file.size,
        length: length,
      }
    })

  const body = props.upload.file

  return new Promise((resolve, reject) => {
    let xhr = request.value
    xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload_session/append_batch', true)
    xhr.setRequestHeader('Authorization', `Bearer ${uploadEndpoint.value!.b2_authtoken}`)
    xhr.setRequestHeader('Content-Type', 'application/octet-stream')
    xhr.setRequestHeader('Dropbox-API-Arg', JSON.stringify({ entries }))
    xhr.responseType = 'json'

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(new Response(xhr.response, { status: xhr.status, statusText: xhr.statusText }))
      } else {
        reject(new Error(`HTTP error! status: ${xhr.status}`))
      }
    }

    xhr.upload.onprogress = (e) => {
      uploadStore.updateUploadProgress({
        id: props.upload.id,
        payload: {
          bytesUploaded: e.loaded,
        },
      })
    }

    xhr.onerror = () => {
      reject(new Error('Network error occurred'))
    }

    xhr.send(body)
  })
}

const finishBatch = async (sessionIds: string[]): Promise<Response> => {
  const entries = [
    {
      cursor: {
        session_id: sessionIds[0],
        offset: props.upload.file.size,
      },
      commit: {
        path: uploadPath.value?.b2_path,
        mode: 'overwrite',
        autorename: true,
        mute: false,
      },
    },
  ]

  const response = await fetch(
    'https://api.dropboxapi.com/2/files/upload_session/finish_batch_v2',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${uploadEndpoint.value!.b2_authtoken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ entries }),
    },
  )

  const clonedResponse = response.clone()
  const result = await clonedResponse.json()
  if (result?.entries?.[0]?.['failure']?.['.tag'] === 'too_many_write_operations') {
    throw new Error('Too many write operations.')
  }

  return response
}

const uploadSmallFile = async (file: File, filePath: string): Promise<void> => {
  const upload = () =>
    new Promise<Response>((resolve, reject) => {
      let xhr = request.value
      xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload')
      xhr.setRequestHeader('Authorization', `Bearer ${uploadEndpoint.value!.b2_authtoken}`)
      xhr.setRequestHeader(
        'Dropbox-API-Arg',
        JSON.stringify({
          path: filePath,
          mode: 'add',
          autorename: true,
          mute: false,
        }),
      )
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')

      const bytesLoaded = (uploadProgressBytes.value || 0) as number

      xhr.upload.onprogress = (e) => {
        uploadStore.updateUploadProgress({
          id: props.upload.id,
          payload: {
            bytesUploaded: bytesLoaded + e.loaded,
          },
        })
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(
            new Response(xhr.response, {
              status: xhr.status,
              statusText: xhr.statusText,
            }),
          )
        } else {
          reject(new Error(`HTTP error! status: ${xhr.status}`))
        }
      }

      xhr.onerror = () => reject(new Error('Network error'))

      xhr.send(file)
      request.value = xhr
    })

  try {
    const response = await retryableRequest(upload)
    const result = await response.json()
    handleUploadResponse({ status: response.status, response: JSON.stringify(result) })
  } catch (error) {
    // console.error('Failed to upload file after multiple retries:', error)
    updateStoreUpload({
      error: true,
      errorReason: 'max_retries_reached',
    })
  }
}

const uploadLargeFile = async (file: File, filePath: string): Promise<void> => {
  let offset = 0
  let sessionId: string | null = null

  try {
    // Start the upload session
    sessionId = await startUploadDropboxSession(file.slice(0, Math.min(CHUNK_SIZE, file.size)))
    offset = Math.min(CHUNK_SIZE, file.size)

    // If file size is larger than CHUNK_SIZE, continue uploading chunks
    while (offset < file.size) {
      const chunk = file.slice(offset, Math.min(offset + CHUNK_SIZE, file.size))
      await appendToUploadSession(sessionId, chunk, offset)
      offset += chunk.size
    }

    // Always finish the upload session, even for small files
    const finalChunk = file.size > CHUNK_SIZE ? new Blob() : file // Empty blob if we've already uploaded all data
    await finishUploadSession(sessionId, finalChunk, offset, filePath)
  } catch (error) {
    updateStoreUpload({
      error: true,
      errorReason: 'upload_failed',
    })
  }
}

const startUploadDropboxSession = async (chunk: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    let xhr = request.value
    xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload_session/start', true)
    xhr.setRequestHeader('Authorization', `Bearer ${uploadEndpoint.value!.b2_authtoken}`)
    xhr.setRequestHeader('Content-Type', 'application/octet-stream')
    xhr.responseType = 'json'

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response.session_id)
      } else {
        reject(new Error(`Failed to start upload session: ${xhr.statusText}`))
      }
    }

    xhr.onerror = () => {
      reject(new Error('Network error occurred'))
    }

    xhr.send(chunk)
  })
}

const appendToUploadSession = async (sessionId: string, chunk: Blob, offset: number) => {
  await retryableRequest(() => {
    return new Promise((resolve, reject) => {
      const xhr = request.value
      const url = 'https://content.dropboxapi.com/2/files/upload_session/append_v2'

      xhr.open('POST', url, true)
      xhr.setRequestHeader('Authorization', `Bearer ${uploadEndpoint.value!.b2_authtoken}`)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')
      xhr.setRequestHeader(
        'Dropbox-API-Arg',
        JSON.stringify({
          cursor: {
            session_id: sessionId,
            offset: offset,
          },
          close: false,
        }),
      )
      xhr.responseType = 'json'

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(new Response(xhr.response, { status: xhr.status, statusText: xhr.statusText }))
        } else {
          reject(new Error(`Error: ${xhr.status} - ${xhr.statusText}`))
        }
      }

      xhr.upload.onprogress = (e) => {
        uploadStore.updateUploadProgress({
          id: props.upload.id,
          payload: {
            bytesUploaded: offset + e.loaded,
          },
        })
      }

      xhr.onerror = () => reject(new Error('Network error'))

      xhr.send(chunk)
    })
  })
}

const finishUploadSession = async (
  sessionId: string,
  chunk: Blob,
  offset: number,
  filePath: string,
) => {
  const upload = () => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://content.dropboxapi.com/2/files/upload_session/finish', true)
      xhr.setRequestHeader('Authorization', `Bearer ${uploadEndpoint.value!.b2_authtoken}`)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')
      xhr.setRequestHeader(
        'Dropbox-API-Arg',
        JSON.stringify({
          cursor: {
            session_id: sessionId,
            offset: offset,
          },
          commit: {
            path: filePath,
            mode: 'add',
            autorename: true,
            mute: false,
          },
        }),
      )

      xhr.upload.onprogress = (e) => {
        uploadStore.updateUploadProgress({
          id: props.upload.id,
          payload: {
            bytesUploaded: offset + e.loaded,
          },
        })
      }

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(
            new Response(xhr.response, {
              status: xhr.status,
              statusText: xhr.statusText,
            }),
          )
        } else {
          reject(new Error(`HTTP error! status: ${xhr.status}`))
        }
      }

      xhr.onerror = function () {
        reject(new Error('Network error occurred'))
      }

      xhr.send(chunk)
    })
  }

  const response = await retryableRequest(upload)
  const result = await response.json()
  handleUploadResponse({ status: response.status, response: JSON.stringify(result) })
}

const handleUploadResponse = async (req: { status: number; response: string }) => {
  if (req.status === 200) {
    const response = JSON.parse(req.response)

    if (response['.tag'] === 'failure') {
      handleUploadError(500)
    } else {
      updateStoreUpload({
        hash: response.id,
      })

      uploadCompleted.value.push({
        sid: uploadState.value?.sid || 'undefined',
        fuid: props.upload.id,
        shasum: [response.content_hash],
      })
    }
  } else if (req.status === 429) {
    await handle429Error(req)
  } else {
    handleUploadError(req.status)
  }

  updateStoreUpload({
    uploading: false,
  })

  queNextFile()
}
const handleUpload = () => {
  return STORAGE_DRIVER === 'dropbox' ? handleUploadDropbox() : handleUploadBackblaze()
}

const removeFromQue = () => {
  uploadQue.value = uploadQue.value.filter((g) => {
    if (g.sid === sid.value) {
      return {
        sid: g.sid,
        list: g.list.map((x) => x.id !== props.upload.id),
      }
    }

    return g
  })
}

// add next file to upload que or remove
const queNextFile = () => {
  const nextUploadState = uploadStates.value.find((s) => !s.uploading && !s.hash && !s.error)
  if (nextUploadState) {
    uploadQue.value = uploadQue.value.map((g) => {
      if (g.sid === sid.value) {
        return {
          sid: g.sid,
          list: g.list.map((x) => {
            if (x.id === props.upload.id) {
              return {
                ...x,
                id: nextUploadState.fuid,
              }
            }

            return x
          }),
        }
      }

      return g
    })
  } else {
    removeFromQue()
  }
}

const startChunkedUpload = () => {
  if (uploadState.value?.hash || !partialEndpoints.value) return

  updateStoreUpload({ uploading: true, error: false, bytesTotal: props.upload.file.size })

  // initial chunk start
  chunkQue.value.forEach((chunkState, index) => {
    if (chunkState === null) return // not queued
    uploadSingleChunk(index, index)
  })
}

const uploadSingleChunk = async (chunkIndex: number, endpointIndex: number, retryCount = 1) => {
  const request = chunkRequests.value[chunkIndex]
  const endpoint = partialEndpoints.value![endpointIndex]
  request.open('POST', endpoint.b2_uploadurl)

  request.setRequestHeader('Authorization', endpoint.b2_authtoken)
  request.setRequestHeader('X-Bz-Part-Number', (chunkIndex + 1).toString())
  request.setRequestHeader('Content-Type', 'b2/x-auto')
  request.setRequestHeader('X-Bz-Content-Sha1', 'do_not_verify')

  let uploadData = props.upload.file.slice(
    uploaderConfig.chunkSizeLargeFile * chunkIndex,
    uploaderConfig.chunkSizeLargeFile * (chunkIndex + 1),
  )

  request.upload.onprogress = (e) => {
    const bytesLoadedArr = uploadProgressBytes.value as number[]
    bytesLoadedArr[chunkIndex] = e.loaded

    uploadStore.updateUploadProgress({
      id: props.upload.id,
      payload: {
        bytesUploaded: bytesLoadedArr,
      },
    })
  }

  request.onload = async function () {
    if (request.status === 200) {
      const responce = JSON.parse(request.response) as IB2UploadPartDto

      const nextQueIndex = chunkQue.value.findIndex((x) => x === null)
      log.log(chunkIndex, responce.contentSha1)
      chunkQue.value[chunkIndex] = false
      chunkSums.value[chunkIndex] = responce.contentSha1

      if (nextQueIndex !== -1) {
        chunkQue.value[nextQueIndex] = true

        // use current endpoint assuming that its free
        uploadSingleChunk(nextQueIndex, endpointIndex)
      }
    } else if (request.status === 429) {
      await handle429Error(request)
      // Retry the chunk upload after handling the 429 error
      await uploadSingleChunk(chunkIndex, endpointIndex, retryCount)
    } else {
      // try repeating 3 times for network errors, otherwise throw file error
      if (retryCount >= 3) {
        handleUploadError(request.status)
      } else {
        setTimeout(() => {
          uploadSingleChunk(chunkIndex, endpointIndex, retryCount + 1)
        }, 300)
      }
    }
  }

  request.onerror = () => {
    updateStoreUpload({
      uploading: false,
      error: true,
      errorReason: 'network',
    })
  }

  request.send(uploadData)
}

const handle429Error = async (response: Response): Promise<void> => {
  const retryAfter = response.headers.get('Retry-After')
  const retryAfterSeconds = retryAfter ? parseInt(retryAfter, 10) : DEFAULT_RETRY_DELAY // Default seconds if header is missing

  const errorBody = await response.text()

  // Log the error to Sentry
  logErrorToSentry('Dropbox API rate limit exceeded', {
    uploadId: props.upload.id,
    retryAfter: retryAfterSeconds,
    response: req.response,
  })

  // Update upload state
  updateStoreUpload({
    error: true,
    errorReason: 'rate_limit',
  })

  // log.warn(`Rate limit exceeded. Retrying after ${retryAfterSeconds} seconds.`)

  // Wait for the specified time
  await new Promise((resolve) => setTimeout(resolve, retryAfterSeconds * 1000))
}

const retryableRequest = async <T,>(requestFn: () => any): Promise<T> => {
  let retries = 0
  while (MAX_RETRIES === 'Infinity' || retries < Number(MAX_RETRIES)) {
    try {
      const response = await requestFn()
      if (response.ok) {
        return response
      } else if (response.status === 429) {
        await handle429Error(response)
        // Don't increment retries for 429 errors, as we're following the Retry-After header
      } else {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      if (error instanceof Error && error.message.includes('HTTP error! status: 429')) {
        continue // Don't increment retries for 429 errors
      }
      // console.error('Upload error:', error)
      Sentry.captureException(error, {
        extra: {
          uploadId: props.upload.id,
          retryAttempt: retries + 1,
        },
      })
      retries++
      if (MAX_RETRIES !== 'Infinity' && retries >= Number(MAX_RETRIES)) {
        throw error
      }

      await new Promise((resolve) => setTimeout(resolve, DEFAULT_RETRY_DELAY * 1000))
    }
  }
  throw new Error('Max retries reached')
}

const handleUploadError = (reqStatus: number) => {
  let reason = 'network' // 409
  switch (reqStatus) {
    case 500:
      reason = 'busy'
      break
    case 410:
      reason = 'expired'
      break
    case 429:
      reason = 'rate_limit'
      break
    case 401:
      reason = 'access_token_issue'
      break
    default:
      break
  }

  logErrorToSentry(`Upload error: ${reason}`, {
    uploadId: props.upload.id,
    status: reqStatus,
  })

  updateStoreUpload({
    error: true,
    errorReason: reason as any,
  })
}

// start / restart
const startUploadSession = async () => {
  // dont start uploads with validation errors
  if (props.upload.error) return

  handleUpload()
}

// repeat on network errors or session expiration
const restartUploadSession = async () => {
  if (uploadState.value?.hash) return

  if (uploadState.value?.error) {
    uploadStore.updateUploadProgress({
      id: props.upload.id,
      payload: {
        bytesUploaded: 0,
      },
    })

    handleUpload()
  }
}

// pause / resume
const pauseUpload = async () => {
  updateStoreUpload({
    paused: true,
  })

  request.value?.abort()
}

const resumeUpload = async () => {
  updateStoreUpload({
    paused: false,
  })

  handleUpload()
}

// watch for the que (regular)
watch(
  () => uploadEndpoint.value,
  (newEndpoint) => {
    if (!newEndpoint) return

    const { b2_authtoken, b2_uploadurl } = newEndpoint
    if (b2_authtoken && b2_uploadurl) {
      startUploadSession()
    } else {
      uploadStore.removeUpload(props.upload.id)
    }
  },
  { immediate: true },
)

// watch for endpoint existance (largeFile)
watch(
  () => partialEndpoints.value,
  (b2Endpoints) => {
    if (b2Endpoints) {
      const totalChunks = Math.ceil(props.upload.file.size / uploaderConfig.chunkSizeLargeFile)
      chunkRequests.value = [...Array(totalChunks)].map(() => new XMLHttpRequest())
      chunkQue.value = [...Array(totalChunks)].map((_, idx) => {
        if (idx + 1 <= b2Endpoints.length) return true
        return null
      })
      chunkSums.value = [...Array(totalChunks)].map((_) => '')

      startChunkedUpload()
    }
  },
  { immediate: true },
)

// watch all chunks ended and notify backend about completion
watch(
  () => chunkSums.value,
  (newChunksums) => {
    if (!newChunksums.every((x) => x)) return

    // get fileId from responce
    const lastRes = JSON.parse(
      chunkRequests.value[chunkRequests.value.length - 1].response,
    ) as IB2UploadPartDto

    updateStoreUpload({
      hash: lastRes.fileId || lastRes.id,
    })

    uploadCompleted.value.push({
      sid: uploadState.value?.sid || 'undefined',
      fuid: props.upload.id,
      shasum: newChunksums,
    })

    updateStoreUpload({
      uploading: false,
    })

    queNextFile()
  },
  { deep: true },
)

watch(
  () => uploadState.value?.error,
  (isError) => {
    if (isError) {
      removeFromQue()
    }
  },
)

// event bus watchers
watch(
  () => bus.value.get('restartUploadSession'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.upload.id) {
      restartUploadSession()
    }
  },
)

watch(
  () => bus.value.get('pauseUpload'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.upload.id) {
      pauseUpload()
    }
  },
)

watch(
  () => bus.value.get('resumeUpload'),
  (val) => {
    const [p] = val ?? []
    if (p.id === props.upload.id) {
      resumeUpload()
    }
  },
)
</script>
