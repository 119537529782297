<template>
  <div
    id="uploadProgress"
    class="fixed bottom-0 right-0 z-[9999] max-h-[80vh] min-w-[100vw] max-w-[100vw] overflow-hidden border bg-gray-100 text-gray-900 shadow-xl dark:border-gray-700 dark:bg-gray-800 dark:text-white sm:min-w-[200px] sm:rounded-t-xl md:right-4"
    :class="[expanded && 'md:w-[500px]']"
  >
    <div class="flex items-center px-4 py-2 pr-1">
      <span>Uploads</span>
      <div class="ml-auto flex items-center">
        <UiButton size="small" theme="link" @click="expanded = !expanded">
          <SvgIcon
            :path="mdiChevronUp"
            size="lg"
            aria-hidden="true"
            class="transition-transform duration-[250]"
            :class="[expanded && 'rotate-180 transform']"
          />
        </UiButton>
        <UiButton size="small" theme="link" @click="close">
          <SvgIcon :path="mdiClose" aria-hidden="true" />
        </UiButton>
      </div>
    </div>
    <template v-if="!routeSid && expanded">
      <div class="flex justify-around border-t border-t-gray-800 p-4 dark:border-t-slate-200">
        <UiRadio
          v-for="fltr in availableStatuses"
          type="checkbox"
          :key="fltr.value"
          :checked="uploadFilter[fltr.value as TStatus]"
          className="text-nowrap"
          @click.prevent="
            () => {
              handleFilterChange(fltr.value as TStatus)
            }
          "
        >
          <span
            :class="[
              'hidden dark:text-gray-200 md:inline-block',
              {
                'text-gray-200': uploadFilter[fltr.value as TStatus],
                'text-gray-700': !uploadFilter[fltr.value as TStatus],
              },
            ]"
            >{{ fltr.label }}</span
          >
        </UiRadio>
        <UiRadio type="checkbox" :checked="true" @click.prevent="() => uploadStore.resetFilter()">
          <i class="icon-filter-reset h-5 w-5 bg-gray-200"></i>
        </UiRadio>
        <UiButton size="small" theme="danger" @click="handleClearAll">Clear All</UiButton>
      </div>
    </template>

    <div
      v-show="expanded"
      class="max-h-[70vh] overflow-auto bg-white px-2 py-4 dark:bg-gray-900 md:px-4"
    >
      <UploadList />
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/components/Ui/SvgIcon.vue'
import UiButton from '@c/Ui/Button.vue'
import { mdiChevronUp, mdiClose } from '@mdi/js'
import UploadList from './UploadList.vue'

import { IUploadWithFile, TStatus, IUploadFilterSet, IUploadState } from '@/core/interface'
import UploadFileDisplay from '@c/Uploader/UploadFileDisplay.vue'
import UiRadio from '../Ui/Radio.vue'

const uploadStore = useUploadStore()
const close = () => {
  uploadStore.setIsVisibleUploadWindow(false)
  updatePosition()
}
const { updatePosition } = useGalleryScrollTopPosition()

const route = useRoute()
const routeSid = computed(() => {
  return route.params.sid as string
})

const expanded = ref(true)

const { uploadStates, uploadSessions, uploadFilter } = storeToRefs(uploadStore)

const handleFilterChange = (key: TStatus) => {
  uploadStore.setFilter(key as TStatus, !uploadFilter.value[key as TStatus])
}

const availableStatuses = computed(() => {
  return [
    { value: 'uploading', label: 'Uploading', icon: 'upload' },
    { value: 'failed', label: 'Failed', icon: 'error' },
    { value: 'completed', label: 'Completed', icon: 'complete' },
  ] as { value: TStatus; label: string; icon?: string }[]
})

const handleClearAll = () => {
  uploadStore.clearUploads()
  updatePosition()
}

watch(expanded, () => {
  updatePosition()
})

onMounted(() => {
  updatePosition()
})
</script>
