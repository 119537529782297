export function useGalleryScrollTopPosition() {
  const updatePosition = () => {
    setTimeout(() => {
      const uploadProgress = document.getElementById('uploadProgress')
      const height = uploadProgress?.clientHeight
      const scrollToTop = document.getElementById('scrollToTop')
      if (!scrollToTop) return
      if (!height) {
        scrollToTop.style.bottom = '6rem'
        return
      }
      scrollToTop.style.bottom = `${height + 20}px`
    }, 80)
  }

  return { updatePosition }
}
