import {
  IListWithSid,
  IUpload,
  IUploadProgress,
  IUploadState,
  TStatus,
  IUploadFilterSet,
} from '@/core/interface'
import {
  B2PartUploadEndpoint,
  B2UploadEndpoint,
  FileUploadComplete,
  FileUploadPathB2,
  FileUploadSession,
  NotifyFileUploadComplete,
  NotifyFileUploadCompleteVariables,
} from '@/graphql/generated'
import throttle from 'lodash/throttle'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useUploadStore = defineStore(
  'upload',
  () => {
    const isVisibleUploadWindow = ref(false)
    const setIsVisibleUploadWindow = (val: boolean) => (isVisibleUploadWindow.value = val)

    const isNewFilesAdding = ref(false)

    const uploadSessions = ref<FileUploadSession[]>([])
    const uploadEndpoints = ref<IListWithSid<B2UploadEndpoint>[]>([])
    const uploadPartialEndpoints = ref<IListWithSid<B2PartUploadEndpoint>[]>([])
    const uploadPaths = ref<FileUploadPathB2[]>([])

    const uploads = ref<IUpload[]>([])
    const uploadQue = ref<IListWithSid<{ id: string; endpoint: number }>[]>([])
    const uploadStates = ref<IUploadState[]>([])
    const numberOfUploadFile = ref<number>(0)
    const uploadProgress = ref<IUploadProgress[]>([])
    const uploadCompleted = ref<FileUploadComplete[]>([])

    const route = useRoute()
    const uiStore = useUiStore()

    const updateUploadState = ({ id, payload }: { id: string; payload: Partial<IUploadState> }) => {
      uploadStates.value = uploadStates.value.map((u) =>
        u.fuid === id
          ? {
              ...u,
              ...payload,
            }
          : u,
      )
    }

    const updateUploadProgress = ({
      id,
      payload,
    }: {
      id: string
      payload: Omit<IUploadProgress, 'fuid'>
    }) => {
      uploadProgress.value = uploadProgress.value.map((u) =>
        u.fuid === id
          ? {
              ...u,
              ...payload,
            }
          : u,
      )
    }

    const removeUpload = async (id: string) => {
      const { removeUploadFromDB } = await useFileDB()

      uploads.value = uploads.value.filter((x) => x.id !== id)
      uploadPaths.value = uploadPaths.value.filter((x) => x.fuid !== id)
      if (uploadStates.value.some((x) => x.fuid === id)) {
        uploadStates.value = uploadStates.value.filter((x) => x.fuid !== id)
      }
      uploadProgress.value = uploadProgress.value.filter((x) => x.fuid !== id)
      removeUploadFromDB(id)
    }

    const clearUploads = () => {
      uploads.value.forEach((x) => removeUpload(x.id))
    }

    // notify compleated uploads with throttle
    const handleNotifyCompleteBatch = throttle(
      async (arr: FileUploadComplete[]) => {
        const { data, error } = await urqlClient.query<
          NotifyFileUploadComplete,
          NotifyFileUploadCompleteVariables
        >(
          NotifyFileUploadCompleteDocument,
          {
            files: arr,
          },
          {
            requestPolicy: 'network-only',
          },
        )
        if (!data?.notifyFileUploadComplete?.error) {
          const completedIds = arr.map((x) => x.fuid)
          uploadCompleted.value = uploadCompleted.value.filter(
            (x) => !completedIds.includes(x.fuid),
          )
        }
      },
      import.meta.env.VITE_UPLOAD_NOTIFY_THROTTLE || 5000,
      { leading: false, trailing: true },
    )

    let isUploadInProgress = false // Flag to track upload progress

    watch(
      () => uploadCompleted.value,
      async (newCompleted) => {
        // In addition, when uploading a file, it will be called
        if (!newCompleted.length) {
          // All uploads are done, notify immediately
          await handleNotifyCompleteBatch.flush() // Flush any pending notifications
          await handleNotifyUploadStates.flush()
          isUploadInProgress = false
          return
        }
        if (numberOfUploadFile.value === 1) {
          handleNotifyCompleteBatch(newCompleted)
          await handleNotifyCompleteBatch.flush()
          return
        }

        // Uploads are still in progress
        isUploadInProgress = true
        handleNotifyCompleteBatch(newCompleted)
      },
      { deep: true },
    )

    // update new batch
    watch(
      () => uploadQue.value,
      (newBatches, oldBatches) => {
        newBatches.forEach((newBatch, batchIndex) => {
          const oldBatch = oldBatches.find((x) => newBatch.sid === x.sid)
          if (!oldBatch) return

          if (oldBatch.list.length && !newBatch.list.length) {
            const nextStates = uploadStates.value
              .filter((s) => s.sid === newBatch.sid)
              .filter((s) => !s.uploading && !s.hash && !s.error)
              .slice(0, uploaderConfig.maxBatch)

            if (nextStates.length) {
              uploadQue.value[batchIndex] = {
                sid: newBatch.sid,
                list: nextStates.map((x, idx) => ({
                  id: x.fuid,
                  endpoint: idx,
                })),
              }
            }
          }
        })
      },
    )

    // notify completed / failed uploads with throttle
    const handleNotifyUploadStates = throttle(
      async (newUploadStates: IUploadState[], prevUploadStates: IUploadState[]) => {
        newUploadStates.forEach((u, idx) => {
          const name = uploads.value.find((upload) => upload.id === u.fuid)?.name

          if (u.hash) {
            // resolved, compare previous
            if (u.hash !== prevUploadStates[idx].hash) {
              uiStore.addToast({
                type: 'success',
                message: 'Some uploads resolved, click here to review them',
                link: '/uploads',
              })
            }
          } else if (u.error) {
            // erred, compare previous
            if (u.error !== prevUploadStates[idx].error) {
              uiStore.addToast({
                type: 'error',
                message: 'Some uploads failed, click here to review them',
                link: '/uploads?s=failed',
              })
            }
          }
        })
      },
      import.meta.env.VITE_UPLOAD_NOTIFY_THROTTLE || 5000,
      { leading: false, trailing: true },
    )

    // show notification of uploads when navigated out of homepage
    watch(
      () => uploadStates.value,
      (newUploadStates, prevUploadStates) => {
        if (route.path.includes('/upload') || !newUploadStates.length || !prevUploadStates.length)
          return

        handleNotifyUploadStates(newUploadStates, prevUploadStates)
      },
    )

    const initialFilterValue: IUploadFilterSet = {
      uploading: false,
      failed: false,
      completed: false,
    }

    const uploadFilter = ref<IUploadFilterSet>({ ...initialFilterValue })

    const setFilter = (key: TStatus, value: boolean) => {
      uploadFilter.value = { ...uploadFilter.value, [key]: value }
    }

    const resetFilter = () => {
      uploadFilter.value = { ...initialFilterValue }
    }

    return {
      isNewFilesAdding,
      uploadEndpoints,
      uploadPartialEndpoints,
      uploads,
      uploadSessions,
      uploadPaths,
      uploadQue,
      uploadStates,
      uploadProgress,
      uploadCompleted,
      removeUpload,
      clearUploads,
      updateUploadState,
      updateUploadProgress,
      isVisibleUploadWindow: computed(() => isVisibleUploadWindow.value),
      setIsVisibleUploadWindow,
      uploadFilter,
      setFilter,
      resetFilter,
      numberOfUploadFile,
      // refreshUploadSession,
    }
  },
  {
    persist: {
      paths: [
        'uploadSessions',
        'uploadEndpoints',
        'uploadPartialEndpoints',
        'uploadPaths',
        'uploads',
        'uploadStates',
        'uploadProgress',
        'uploadQue',
      ],
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUploadStore, import.meta.hot))
}

// const refreshUploadSession = async () => {
//   if (!uploadSession.value?.sid) return

//   const { data, error } = await urqlClient.query<
//     RefreshFileUploadSessionQuery,
//     RefreshFileUploadSessionQueryVariables
//   >(
//     refreshFileUploadSessionQuery,
//     {
//       sessionId: uploadSession.value?.sid,
//     },
//     {
//       requestPolicy: 'network-only',
//     },
//   )

//   if (data?.refreshFileUploadSession) {
//     if (data.refreshFileUploadSession.session) {
//       uploadSession.value = data.refreshFileUploadSession.session
//     }
//     if (data.refreshFileUploadSession.urls) {
//       uploadPaths.value = data.refreshFileUploadSession?.urls

//       const newBatch = data.refreshFileUploadSession.urls.map((x) => x.fuid)

//       uploadQue.value = newBatch.slice(0, uploaderConfig.maxBatch)
//     }
//   }

//   return data
// }
