<script setup lang="ts">
import { useIntervalFn, whenever } from '@vueuse/core'

const { executeQuery, data } = useGetBackendStatus({ requestPolicy: 'cache-and-network' })
const version = computed(() => data.value?.getBackendStatus?.version)
const local_version = computed(() => import.meta.env.VITE_APP_VERSION)
const status = computed(() =>
  data.value?.getBackendStatus?.status === 'online' ? 'online' : 'offline',
)
const minute = 5 * 60 * 1000
const { pause, resume } = useIntervalFn(() => executeQuery(), minute, { immediate: false })
whenever(data, resume)
onUnmounted(pause)
</script>

<template>
  <div v-show="version" class="ml-6 flex items-center">
    <span class="text-sm text-gray-500 dark:text-gray-200"
      >{{ local_version }} : {{ version }}
    </span>
    <span
      :class="status === 'online' ? 'bg-green-500' : 'bg-red-500'"
      class="ml-2 h-2 w-2 rounded-full"
      title="Backend Status"
    ></span>
  </div>
</template>
