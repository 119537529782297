<template>
  <!-- <div
    class="h-fit w-fit rounded-lg bg-blue-600 p-[1px] text-gray-100 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-[0px_0px_0px_2px_rgba(255,255,255,1),0px_0px_0px_3px_rgb(212,209,224,1)] active:bg-blue-700 active:shadow-[0px_0px_0px_2px_rgb(30,64,175,1)]"
  > -->
  <div
    class="h-fit w-fit rounded-lg"
    :class="{ 'border-blue-600': checked, 'bg-blue-600': checked }"
  >
    <input
      class="radiobutton"
      :type="type"
      :name="name || ''"
      :id="id ? `radio-${id}` : `radio-${name}`"
      :checked="checked"
      :onChange="() => emits('change')"
    />
    <label
      class="rounded-lg border-2 border-blue-600 bg-white text-gray-700 checked:text-gray-200 dark:bg-gray-900 dark:text-gray-200"
      :class="{ 'border-blue-600': checked, 'bg-blue-600': checked }"
      :for="id ? `radio-${id}` : `radio-${name}`"
      :name="name || ''"
    >
      {{ title }}
      <slot></slot>
    </label>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title?: string
    checked?: boolean
    type?: 'checkbox' | 'radio'
    name?: string
    id?: string
    // theme?: 'primary' | 'danger' | 'link'
    // size?: 'large' | 'medium' | 'small'
    // to?: string
    // iconRight?: string
    // iconLeft?: string
    // loading?: boolean
    // loadingIconSize?: SvgIconProps['size']
  }>(),
  {
    checked: false,
    type: 'checkbox',
  },
)

const emits = defineEmits(['change'])
</script>

<style lang="scss">
.radiobutton {
  display: none;
  & + label {
    display: inline-flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.2s ease;
    &:hover {
      cursor: pointer;
    }
  }
  &:checked + label {
    display: inline-flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    font-weight: 600;
    background-color: rgb(37 99 235 / var(--tw-bg-opacity));
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s ease;
  }
}
</style>
