export const uploaderConfig = {
  useFrontendValidation: true,
  allowedMimes: ['image/png', 'image/jpeg', 'image/bmp'],
  allowedArchives: ['zip', 'tar', 'tgz', 'gz', '7z'],
  allowedImgLike: [
    '3fr',
    'dcr',
    'crw',
    'cr2',
    'cr3',
    'nef',
    'nrw',
    'orf',
    'pef',
    'rw2',
    'srf',
    'sr2',
    'tif',
    'tiff',
    'fit',
    'fits',
    'xisf',
    'png',
    'jpg',
  ],
  minBytes: 16 * 1024,
  maxBytes: 16 * 1024 * 1024 * 1024, // 16 gb (single and/or total)
  maxBatch: 6,
  maxFilesTotal: 200,
  readerMaxSize: 10 * 1024 * 1024, // max size to ask fileread (preview, image px size)
  chunkSizeLargeFile: 64 * 1024 * 1024, // size of chunk for b2_upload_part
}
