<template>
  <div
    class="flex flex-wrap items-center justify-between rounded-md bg-gray-100 px-4 py-2 dark:bg-gray-800 md:flex-nowrap"
  >
    <div
      class="flex min-w-px flex-1 flex-col items-center md:mr-4 md:w-5/12 md:basis-10/12 md:flex-row"
    >
      <div
        class="relative mt-2 h-14 w-14 shrink-0 overflow-hidden rounded-md bg-gray-200 dark:bg-gray-700 md:mr-auto md:mt-0"
      >
        <img class="h-full w-full object-cover" v-if="dataURL" :src="dataURL" :alt="upload.name" />

        <div
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-500 transition-[opacity] dark:text-white"
          v-else
        >
          <SvgIcon v-if="upload.type === 'archive'" :path="mdiFolderZip" size="xl" />
          <SvgIcon
            v-else-if="['image', 'imagelike'].includes(upload.type)"
            :path="mdiFileImage"
            size="xl"
          />
        </div>
      </div>
      <div class="text-md mt-2 w-full basis-full truncate px-4 md:mt-0 md:basis-auto md:pr-2">
        {{ upload.name }} ({{ formatBytes(upload.file.size) }})
      </div>
    </div>

    <div class="items-left mt-4 flex md:mt-0">
      <div class="relative flex h-6 w-full items-center justify-center" @click="statusButtonAction">
        <UploadStatus :uploadProgressVerbose="uploadProgressVerbose" :uploadState="uploadState" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// This component only display state of uploading for cross-page use

import SvgIcon from '@c/Ui/SvgIcon.vue'
import { IUploadState, IUploadWithFile } from '@/core/interface'
import { formatBytes } from '@/core/utils/File'
import { mdiFileImage, mdiFolderZip } from '@mdi/js'
import UploadStatus from './UploadStatus.vue'

const props = defineProps<{ upload: IUploadWithFile }>()
const emit = defineEmits(['onRemove'])

const log = useLogger()
const router = useRouter()
const uploadStore = useUploadStore()
const { uploadPartialEndpoints, uploadStates, uploadProgress } = storeToRefs(uploadStore)

const { busEmit } = useEventBus()

// corresponding state of the file
const uploadState = computed(() => {
  return uploadStates.value.find((x) => x.fuid === props.upload.id) as IUploadState
})

const uploadProgressBytes = computed(() => {
  return uploadProgress.value.find((x) => x.fuid === props.upload.id)?.bytesUploaded
})

// create blob: URL
const dataURL = computed(() => {
  if (props.upload.type === 'image' && props.upload.file.size <= uploaderConfig.readerMaxSize) {
    return window.URL.createObjectURL(props.upload.file)
  }
  return null
})

const isLargeFile = computed(() => {
  let partialEndpointExist = false
  uploadPartialEndpoints.value.forEach((g) => {
    g.list.forEach((x) => {
      if (x.fuid === props.upload.id) {
        partialEndpointExist = true
      }
    })
  })

  return partialEndpointExist
})

const uploadProgressVerbose = computed(() => {
  if (!uploadState.value) return 0

  let bytesLoaded = uploadProgressBytes.value as number
  if (isLargeFile.value && Array.isArray(uploadProgressBytes.value)) {
    bytesLoaded = (uploadProgressBytes.value as number[]).reduce((acc, x) => {
      acc += x
      return acc
    }, 0)
  }

  return Math.round((bytesLoaded / uploadState.value.bytesTotal) * 100) || 0
})

const canViewUpload = computed(() => {
  return uploadState.value?.hash
})

// repeat on network errors or session expiration
const restartUploadSession = async () => {
  busEmit('restartUploadSession', {
    id: props.upload.id,
  })
}

// pause / resume
const pauseUpload = async () => {
  busEmit('pauseUpload', {
    id: props.upload.id,
  })
}

const resumeUpload = async () => {
  busEmit('resumeUpload', {
    id: props.upload.id,
  })
}

const openResult = async () => {
  router.push(`/result/${props.upload.id}`)
}

onBeforeUnmount(() => {
  if (dataURL.value) {
    URL.revokeObjectURL(dataURL.value)
  }
})

const statusButtonAction = () => {
  if (uploadState?.value?.hash) openResult()
  if (uploadState?.value?.uploading && !uploadState?.value?.paused) pauseUpload()
  if (uploadState?.value?.paused) resumeUpload()
  if (uploadState?.value?.error) restartUploadSession()
}
</script>
