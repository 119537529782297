<script setup lang="ts">
export type AvatarProps = {
  readonly label?: string
  readonly url?: string
}
const props = defineProps<AvatarProps>()

const newLabel = computed(() => props.label || 'guest')
</script>

<template>
  <a href="#">
    <img
      v-if="url"
      :src="url"
      alt="avatar"
      class="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-gray-200 bg-white text-[0.625rem] font-medium text-gray-400"
    />
    <span
      v-else
      class="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-gray-200 bg-white text-[0.625rem] font-medium text-gray-400"
    >
      {{ newLabel[0].toUpperCase() }}
    </span>
  </a>
</template>
