<template>
  <component
    :is="getElement"
    :class="[
      'button group',
      'relative inline-block cursor-pointer appearance-none overflow-hidden rounded-lg border border-transparent p-0 text-center shadow-none transition',
      modifiers,
    ]"
    :href="href"
    :to="to"
    v-bind="$attrs"
  >
    <span
      :class="[
        'relative z-[1] inline-flex items-center justify-center',
        contentModifiers,
        showLoader && 'opacity-0',
        'group-[.is-link]:py-1',
      ]"
    >
      <i :class="[!isEmpty && 'mr-1', 'shrink-0 text-xl leading-[0]']" v-if="iconLeft">
        <SvgIcon :path="iconLeft" />
      </i>
      <slot></slot>
      <i :class="[!isEmpty && 'ml-1', 'shrink-0 text-xl leading-[0]']" v-if="iconRight">
        <SvgIcon :path="iconRight" />
      </i>
    </span>

    <span
      :class="[
        'pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-[3] inline-flex items-center justify-center opacity-0 transition',
        showLoader && 'animate-spin opacity-100',
      ]"
      v-if="showLoader"
    >
      <SvgIcon :path="mdiLoading" />
    </span>
  </component>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'
import SvgIcon, { SvgIconProps } from '@c/Ui/SvgIcon.vue'
import { mdiLoading } from '@mdi/js'

const props = withDefaults(
  defineProps<{
    theme?: 'primary' | 'danger' | 'link'
    size?: 'large' | 'medium' | 'small'
    href?: string
    to?: string
    iconRight?: string
    iconLeft?: string
    loading?: boolean
    loadingIconSize?: SvgIconProps['size']
  }>(),
  {
    theme: 'primary',
    size: 'medium',
  },
)

const modifiers = computed(() => {
  let themeStyle

  if (props.theme === 'danger') {
    themeStyle = `
      bg-red-600 text-gray-100
      hover:bg-red-700
      focus:bg-red-700 focus:shadow-[0px_0px_0px_2px_rgba(255,255,255,1),0px_0px_0px_3px_rgb(212,209,224,1)]
      active:bg-red-700 active:shadow-[0px_0px_0px_2px_rgb(30,64,175,1)]
    `
  } else if (props.theme === 'link') {
    themeStyle = `
      hover:text-blue-800 dark:hover:text-blue-300
    `
  } else {
    themeStyle = `
      bg-blue-600 text-gray-100
      hover:bg-blue-700
      focus:bg-blue-700 focus:shadow-[0px_0px_0px_2px_rgba(255,255,255,1),0px_0px_0px_3px_rgb(212,209,224,1)]
      active:bg-blue-700 active:shadow-[0px_0px_0px_2px_rgb(30,64,175,1)]
    `
  }

  return [
    themeStyle,
    'disabled:pointer-events-none disabled:bg-gray-100 disabled:text-gray-800 dark:disabled:bg-gray-400',
    `is-${props.theme}`,
    `is-${props.size}`,
  ]
})

const contentModifiers = computed(() => {
  let sizeStyle
  if (props.size === 'large') {
    sizeStyle = 'px-5 py-4 text-xl font-bold'
  } else if (props.size === 'medium') {
    sizeStyle = 'px-4 py-3 text-lg font-bold'
  } else if (props.size === 'small') {
    sizeStyle = 'px-3 py-2 text-sm font-medium'
  }

  return sizeStyle
})

const getElement = computed(() => {
  if (props.href) {
    return 'a'
  } else if (props.to) {
    return RouterLink
  }

  return 'button'
})

const slots = useSlots()
const isEmpty = computed(() => {
  return !slots.default
})

const showLoader = ref(props.loading)
let timer: NodeJS.Timeout | null = null

watch(
  () => props.loading,
  (newVal) => {
    if (newVal === true) {
      timer = setTimeout(() => {
        showLoader.value = true
      }, 200)
    } else if (newVal === false) {
      showLoader.value = false
      timer && clearTimeout(timer)
    }
  },
)
</script>
