import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/Home.page.vue'),
    },
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('@/pages/Auth/Login.page.vue'),
      meta: {
        disallowAuthorized: true,
      },
    },
    {
      path: '/auth/signup',
      name: 'Signup',
      component: () => import('@/pages/Auth/Signup.page.vue'),
      meta: {
        disallowAuthorized: true,
      },
    },
    {
      path: '/auth/recover',
      name: 'Recover',
      component: () => import('@/pages/Auth/Recover.page.vue'),
      meta: {
        disallowAuthorized: true,
      },
    },
    {
      path: '/auth/confirm',
      name: 'Confirm',
      component: () => import('@/pages/Auth/Confirm.page.vue'),
    },
    {
      path: '/auth/:provider',
      name: 'oAuth',
      component: () => import('@/pages/Auth/Login.page.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/pages/Profile.page.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: () => import('@/pages/Pricing.page.vue'),
    },
    {
      path: '/payment-success',
      name: 'PaymentSuccess',
      component: () => import('@/pages/PaymentSuccess.page.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/result/:id',
      name: 'Recognition',
      component: () => import('@/pages/Recognition.page.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/gallery',
      name: 'Gallery',
      component: () => import('@/pages/Gallery.page.vue'),
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfService',
      component: () => import('@/pages/TermsOfService.page.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'Privacy',
      component: () => import('@/pages/Privacy.page.vue'),
    },
    {
      path: '/400',
      name: 'BadRequest',
      component: () => import('@/pages/BadRequest.page.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'PageNotFound',
      component: () => import('@/pages/NotFound.page.vue'),
    },
    {
      path: '/docs',
      name: 'docs',
      component: () => import('@/pages/Docs.page.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  if (to.path.includes(CONNECT_DROPBOX_ENDPOINT)) {
    return { name: 'Profile', query: { ...to.query, t: 'dropbox' } }
  }
})

export default router

declare module 'vue-router' {
  interface RouteMeta {
    authRequired?: boolean
    disallowAuthorized?: boolean
  }
}
