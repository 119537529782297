<template>
  <div class="">
    <UploadFileLogic v-for="upload in uploadList" :upload="upload" :key="upload.id" />
  </div>
</template>

<script setup lang="ts">
import { IUploadWithFile } from '@/core/interface'
import UploadFileLogic from '@c/Uploader/UploadFileLogic.vue'

const uploadStore = useUploadStore()
const { uploadStates } = storeToRefs(uploadStore)

const { getUploadsFromDb } = await useFileDB()

const uploadList = ref<IUploadWithFile[]>([])

watch(
  () => uploadStates.value.length,
  async (newLength) => {
    const uploadIdsToDisplay = uploadStates.value.map((x) => x.fuid)
    const uploadsWithFile = await getUploadsFromDb(uploadIdsToDisplay)

    uploadList.value = uploadsWithFile.filter((f) => f).filter((x) => !x.error)
  },
  { immediate: true },
)
</script>
