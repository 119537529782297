<template>
  <Menu as="div" class="relative inline-block text-left">
    <div @click="isVisibleDropdown = true">
      <MenuButton
        class="flex items-center space-x-1 px-2 py-4 text-gray-500 transition last:mr-0 hover:text-blue-900 dark:text-gray-200 dark:hover:text-blue-300 lg:mr-3"
      >
        <SvgIcon :path="mdiEmailOutline" class="text-xl text-gray-800 dark:text-gray-200" />
      </MenuButton>
    </div>

    <transition
      v-if="isVisibleDropdown"
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white text-gray-900 shadow-lg ring-1 ring-black/5 focus:outline-none dark:divide-gray-600 dark:bg-gray-800 dark:text-white"
      >
        <template v-if="messages.length">
          <div
            v-for="message in messages"
            :key="message.id"
            class="px-1 py-1"
            @click="isVisibleDropdown = false"
          >
            <MenuItem v-slot="{ active }">
              <div
                :class="[
                  active ? ' text-blue-600' : 'text-gray-900 dark:text-white',
                  'group flex w-full items-center space-x-2 rounded-md px-2 py-2 text-sm',
                ]"
              >
                <div class="flex-1">
                  <span class="text-sm font-bold">{{ message.message }}</span>
                  <RouterLink
                    v-if="message.imagesetId"
                    :to="`/result/${message.imagesetId}`"
                    rel="noopener noreferrer"
                    class="mt-2 block text-xs text-blue-500 hover:underline"
                  >
                    View Image Set
                  </RouterLink>
                </div>
                <button
                  @click="messageStore.deleteMessage(message.id)"
                  class="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-600"
                >
                  <SvgIcon :path="mdiDelete" class="text-xl" />
                </button>
              </div>
            </MenuItem>
          </div>
          <UiButton @click="clearAll" class="mt-2 w-full" size="small" theme="danger">
            {{ $t('header.message.clearAll') }}
          </UiButton>
        </template>
        <p v-else class="py-10 text-center text-gray-600 dark:text-gray-300">
          {{ $t('header.message.noMessage') }}
        </p>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { mdiEmailOutline, mdiDelete } from '@mdi/js'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import UiButton from '@c/Ui/Button.vue'
import { useGetGraphqlSubscriptionInfo } from '@/graphql/generated'
const { t } = useI18n()

const isVisibleDropdown = ref(false)
const messageStore = useMessageStore()
const websocketStore = useWebsocketStore()
const { messages } = storeToRefs(messageStore)

messageStore.fetchMessages()

const clearAll = () => {
  if (confirm(t('header.message.confirmClearAll'))) {
    messageStore.clearMessages()
  }
}

const { data: subscriptionInfo } = useGetGraphqlSubscriptionInfo({
  variables: {},
  pause: computed(() => !websocketStore.isConnected),
})

watch(subscriptionInfo, (val) => {
  if (val?.getGraphqlSubscriptionInfo?.stream) {
    messageStore.subscribeToNotifications(val.getGraphqlSubscriptionInfo.stream)
  }
})

// Handle incoming notifications
watch(
  () => websocketStore.ws,
  (ws) => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.type === 'data' && data.payload.data.onNotification2) {
          const notification = data.payload.data.onNotification2
          messageStore.addMessage(notification.message, notification.imageset_id)
        }
      }
    }
  },
)
</script>
