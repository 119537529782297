<template>
  <Menu as="div" class="relative inline-block text-left">
    <div @click="isVisibleDropdown = true">
      <MenuButton
        class="flex items-center space-x-1 px-2 py-4 text-gray-500 transition last:mr-0 hover:text-blue-900 dark:text-gray-200 dark:hover:text-blue-300 lg:mr-3"
      >
        <SvgIcon v-if="item.icon" :path="item.icon" class="text-xl" />
        <span class="hidden text-sm font-bold md:inline-block">{{ item.label.caption }}</span>
        <SvgIcon :path="mdiChevronDown" class="text-xl" />
      </MenuButton>
    </div>

    <transition
      v-if="isVisibleDropdown"
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white text-gray-900 shadow-lg ring-1 ring-black/5 focus:outline-none dark:divide-gray-600 dark:bg-gray-800 dark:text-white"
      >
        <div
          v-for="(c, index) in item.children"
          :key="index"
          class="px-1 py-1"
          @click="isVisibleDropdown = false"
        >
          <MenuItem v-slot="{ active }">
            <component
              :is="getMenuItemComponent(c)"
              :to="c.to"
              :href="c.href"
              :target="c.target"
              :class="[
                active ? ' text-blue-600' : 'text-gray-900 dark:text-white',
                'group flex w-full cursor-pointer items-center space-x-2 rounded-md px-2 py-2 text-sm',
              ]"
            >
              <SvgIcon v-if="c.icon" :path="c.icon" class="text-xl" />
              <span class="text-sm font-bold">{{ c.label.caption }}</span>
            </component>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { mdiChevronDown } from '@mdi/js'
import type { AnchorHTMLAttributes } from 'vue'
import { RouterLink } from 'vue-router'

type Item = {
  to?: string
  icon?: string
  label: {
    caption: string
    visibleFrom?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  }
  target?: AnchorHTMLAttributes['target']
  href?: string
  visible: boolean
  children?: Item[]
}
type Props = {
  readonly item: Item
}
defineProps<Props>()

const isVisibleDropdown = ref(false)
const getMenuItemComponent = (item: Item) => (item.to ? RouterLink : item.href ? 'a' : 'button')
</script>
