import * as Sentry from '@sentry/vue'

export const logErrorToSentry = (error: Error | string, context: Record<string, any> = {}) => {
  const errorInstance = error instanceof Error ? error : new Error(error)

  Sentry.captureException(errorInstance, {
    extra: {
      ...context,
      timestamp: new Date().toISOString(),
    },
  })
}
