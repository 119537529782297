import { ImageSizeType } from '@/core/interface'

export const OverlayOpacity = {
  default: 0.4,
  hover: 0.6,
  hidden: 0,
  muted: 0.2,
  active: 0.8,
} as const

export const ImageSizeTypes: Record<ImageSizeType, ImageSizeType> = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xlarge: 'xlarge',
  full: 'full',
}
