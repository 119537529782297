<template>
  <div>
    <div class="cookie-banner flex-col gap-4 bg-white p-8 dark:bg-gray-900 sm:flex-row sm:gap-8">
      <p class="text-left font-light leading-tight sm:w-1/2">
        Spline uses cookies, pixel tags, and local storage to improve your experience and analyze
        app usage. Essential cookies and analytics are turned on by default.
      </p>
      <div class="flex flex-row gap-4">
        <UiButton size="small" @click="acceptCookies">Accept</UiButton>
        <UiButton size="small" theme="link" @click="declineCookies">Decline</UiButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import UiButton from '@c/Ui/Button.vue'

const emit = defineEmits(['acceptCookies', 'declineCookies'])
const sessionStore = useSessionStore()

function acceptCookies() {
  sessionStore.setAcceptCookies()
}

function declineCookies() {
  sessionStore.setDeclineCookies()
}
</script>
