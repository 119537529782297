<template>
  <Suspense>
    <LayoutDefault>
      <RouterView v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <keep-alive include="Gallery.page">
            <component :is="Component" :key="$route.fullPath" />
          </keep-alive>
        </transition>
      </RouterView>
      <AcceptCookies v-if="!accepted.value && !selected.value" />
      <UploadWindow v-if="isVisibleUploadWindow" />
    </LayoutDefault>
  </Suspense>
</template>

<script setup lang="ts">
import LayoutDefault from '@c/Layout/Layout.vue'
import AcceptCookies from '@c/Service/AcceptCookies.vue'
import UploadWindow from '@c/Uploader/Window.vue'
import GalleryPage from './pages/Gallery.page.vue'

useApiClientProvider()

const sessionStore = useSessionStore()
const uiStore = useUiStore()

const accepted = computed(() => ref(sessionStore.acceptCookies))
const selected = computed(() => ref(sessionStore.declineCookies))

const log = useLogger()

log.log({ env: import.meta.env })

onMounted(() => {
  if (!uiStore.theme || uiStore.theme === 'dark') {
    document.documentElement.classList.add('dark')
    uiStore.setTheme('dark')
  } else if (
    !('Starpx.theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
})

const uploadStore = useUploadStore()
const { isVisibleUploadWindow } = storeToRefs(uploadStore)

const isProd = import.meta.env.PROD
if (isProd) {
  useHead({
    script: [
      {
        src: 'https://www.googletagmanager.com/gtag/js?id=G-DLDRT50ZMY',
        async: true,
      },
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag("js", new Date());
          gtag("config", "G-DLDRT50ZMY");

          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
            'functionality_storage': 'granted',
            'personalization_storage': 'granted',
            'security_storage': 'granted',
            'wait_for_update': 500
          });
        `,
      },
    ],
  })
}

useSubscriptionSocket({ autoInit: true })
</script>
