import { useGetGraphqlSubscriptionInfo } from '@/graphql/generated'
import { MaybeRef, computed, unref, watch } from 'vue'

type UseSubscriptionSocket = (options?: { autoInit?: MaybeRef<boolean> }) => void

export const useSubscriptionSocket: UseSubscriptionSocket = (options) => {
  const autoInit = computed(() => !!unref(options?.autoInit))

  const sessionStore = useSessionStore()
  const { JWTtokens } = storeToRefs(sessionStore)

  const websocketStore = useWebsocketStore()

  const { data: subscriptionInfo } = useGetGraphqlSubscriptionInfo({
    variables: {},
    pause: computed(() => !autoInit.value || !JWTtokens.value?.access),
  })

  watch(subscriptionInfo, (val) => {
    if (!autoInit.value || !val?.getGraphqlSubscriptionInfo?.uri) return

    websocketStore.init(val.getGraphqlSubscriptionInfo.uri, val.getGraphqlSubscriptionInfo.stream)
  })
}
