const bus = ref(new Map())

export const useEventBus = () => {
  const busEmit = (event: string, ...args: any) => {
    bus.value.set(event, args)
  }

  return {
    busEmit,
    bus,
  }
}
