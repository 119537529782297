import { IUploadWithFile } from '@/core/interface'

let db: IDBDatabase

export async function useFileDB() {
  const DB_KEY = 'uploads-db'
  const VERSION = 1
  const STORE_NAME = 'uploads'

  // Open indexedDB instance and keep it in variable
  // assume that db is created because of mount hook

  const openDB = () => {
    return new Promise((resolve, reject) => {
      try {
        if (db) {
          resolve(db)
          return
        }

        const req = indexedDB.open(DB_KEY, VERSION)

        req.onerror = (e) => {
          throw e
        }
        req.onsuccess = (event) => {
          db = req.result
          resolve(db)
        }
        req.onupgradeneeded = (event) => {
          const db = event.target as IDBRequest<IDBDatabase>
          const store = db.result.createObjectStore(STORE_NAME, { keyPath: 'id' })
          // store.createIndex('id', 'id', { unique: true })
          // resolve(db)
        }
      } catch {
        reject()
      }
    })
  }

  // transaction helper (actual table to do operations with)
  const getObjectStore = (mode: IDBTransactionMode = 'readwrite') => {
    const tx = db.transaction(STORE_NAME, mode)
    return tx.objectStore(STORE_NAME)
  }

  // const clearStore = () => {
  //   const store = getObjectStore()
  //   const req = store.clear()
  //   req.onsuccess = (evt) => {
  //     log.debug('Store cleared')
  //   }
  //   // req.onerror = () => {}
  // }

  const getUploadFromDB = (id: string): Promise<IUploadWithFile> => {
    return new Promise((resolve, reject) => {
      try {
        const store = getObjectStore()
        const res = store.get(id) as IDBRequest<IUploadWithFile>

        res.onsuccess = () => {
          resolve(res.result)
        }

        res.onerror = () => {
          throw new Error()
        }
      } catch {
        reject()
      }
    })
  }

  const getUploadsFromDb = (ids: string[]): Promise<IUploadWithFile[]> => {
    return Promise.all(ids.map((id) => getUploadFromDB(id)))
  }

  const addUploadToDB = (upload: IUploadWithFile): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const store = getObjectStore()

        let req
        try {
          req = store.add(upload)
        } catch (e: any) {
          if (e.name == 'DataCloneError') {
            // eslint-disable-next-line quotes, no-console
            console.error("This engine doesn't know how to clone a Blob")
          }
          throw e
        }

        req.onsuccess = function (evt) {
          resolve(true)
        }
        req.onerror = function () {
          reject()
        }
      } catch {
        reject()
      }
    })
  }

  const removeUploadFromDB = (id: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const store = getObjectStore()

        const res = store.delete(id)

        res.onsuccess = (evt) => {
          resolve(true)
        }

        res.onerror = () => {
          throw new Error()
        }
      } catch {
        reject()
      }
    })
  }

  const getAllUploadsFromDB = async (): Promise<IUploadWithFile[]> => {
    return new Promise((resolve, reject) => {
      try {
        const store = getObjectStore('readonly')

        const res = store.getAll()

        res.onsuccess = (evt) => {
          resolve(res.result as any)
        }

        res.onerror = () => {
          throw new Error()
        }
      } catch {
        reject()
      }
    })
  }

  const resetUploadsFromDB = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        const store = getObjectStore()

        const res = store.clear()

        res.onsuccess = (evt) => {
          resolve(true)
        }

        res.onerror = () => {
          throw new Error()
        }
      } catch {
        reject()
      }
    })
  }

  await openDB()

  return {
    getUploadFromDB,
    getUploadsFromDb,
    addUploadToDB,
    removeUploadFromDB,
    getAllUploadsFromDB,
    resetUploadsFromDB,
  }
}
