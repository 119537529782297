import {
  useAddNotification,
  OnNotificationDocument,
  OnImageSetUpdateDocument,
} from '@/graphql/generated'

export const useWebsocketStore = defineStore('websocket', () => {
  const ws = ref<WebSocket | null>(null)
  const isConnected = ref(false)
  const sessionStore = useSessionStore()
  const { JWTtokens } = storeToRefs(sessionStore)
  const host = ref('')

  const init = (uri: string, stream: string): void => {
    if (!JWTtokens.value?.access) {
      throw new Error('unauthenticated')
    }

    host.value = uri.replace('wss://', '').replace('/graphql', '').replace('-realtime-', '-')
    const header = btoa(JSON.stringify({ host: host.value, Authorization: JWTtokens.value.access }))
    ws.value = new WebSocket(`${uri}?header=${header}&payload=e30=`, 'graphql-ws')

    ws.value.onopen = () => {
      console.log('WebSocket Connected')
      isConnected.value = true
      ws.value?.send(JSON.stringify({ type: 'connection_init' }))

      // TODO: remove test
      onAddNotification()
    }

    ws.value.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log('WebSocket Received: ', data)

      if (data.type === 'connection_ack') {
        if (OnNotificationDocument.loc?.source.body) {
          subscribe(OnNotificationDocument.loc?.source.body)
        }

        if (OnImageSetUpdateDocument.loc?.source.body) {
          subscribeToImageSetUpdates(stream)
        }
      } else if (data.type === 'data') {
        const notification: Notification = data.payload.data.onNotification
        onReceivedMessage(notification)
      }
    }

    ws.value.onerror = (error) => {
      console.error('WebSocket Error:', error)
    }

    ws.value.onclose = () => {
      console.log('WebSocket Closed')
    }
  }
  const onReceivedMessage = (notification: Notification): void => {
    console.log('Received notification:', notification)
  }

  const { executeMutation: addNotification } = useAddNotification()
  const onAddNotification = async () => {
    try {
      const result = await addNotification(
        {},
        { fetchOptions: { headers: { Authorization: JWTtokens.value!.access } } },
      )

      if (result.error) {
        console.error('Error adding notification:', result.error)
      } else {
        console.log('Mutation response:', result.data)
      }
    } catch (error) {
      console.error('Error adding notification:', error)
    }
  }

  const send = (message: any): void => {
    if (!ws.value || !isConnected.value) {
      throw new Error('WebSocket is not connected')
    }
    ws.value?.send(JSON.stringify(message))
  }

  const subscribe = (query: string, variables: any = {}): void => {
    if (!JWTtokens.value?.access) {
      throw new Error('Unauthenticated')
    }

    const subscriptionMessage = {
      id: crypto.randomUUID(),
      payload: {
        data: JSON.stringify({ query, variables }),
        extensions: {
          authorization: {
            host: host.value,
            Authorization: JWTtokens.value!.access,
          },
        },
        variables,
      },
      type: 'start',
    }

    send(subscriptionMessage)
  }

  const subscribeToImageSetUpdates = (stream: string) => {
    if (!JWTtokens.value?.access) {
      throw new Error('Unauthenticated')
    }

    subscribe(OnImageSetUpdateDocument.loc!.source.body, { stream })
  }

  const close = (): void => {
    ws.value?.close()
    ws.value = null
    isConnected.value = false
  }

  return {
    ws: computed(() => ws.value),
    isConnected: computed(() => isConnected.value),
    init,
    send,
    subscribe,
    close,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebsocketStore, import.meta.hot))
}
