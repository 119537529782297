import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createLogger } from 'vue-logger-plugin'
// import { worker } from '../mocks/browser'

import App from '@/App.vue'
import { i18n } from '@/core/i18n'
import router from '@/core/router'
import { createHead } from '@unhead/vue'

import '@/assets/styles/app.scss'

// if (import.meta.env.DEV) {
//   worker.start()
// }

const app = createApp(App)
const head = createHead()

sentryLogging.init(app, router)

const logger = createLogger({
  consoleEnabled: !import.meta.env.PROD,
  level: 'debug',
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(head)
app.use(i18n)
app.use(logger)

app.mount('#app')
